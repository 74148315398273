<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {
  fetchEquipmentsTypesApi,
  fetchEquipmentsCategsApi,
  fetchSingleEquipmentDetailsApi,
  fetchEquipmentsBrandsApi,
} from "@/api/common";
import {
  required, maxLength
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouvel equipement",
    meta: [{ name: "description", content: appConfig.description }]
  },
  import: { fetchEquipmentsTypesApi,
  fetchEquipmentsCategsApi,
  fetchEquipmentsBrandsApi,
  fetchSingleEquipmentDetailsApi,
            },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Nouvel equipement",
      uoms: [],
      categories: [],
      types: [],
      brands: [],
      items: [
        {
          text: "Misc",
          href: "/"
        },
        {
          text: "Equipements",
          href: "/"
        },
        {
          text: "Nouveau",
          active: true
        }
      ],
      equipForm: {
        uuid: "",
        name: "",
        description: "",
        category: "",
        type: "",
        brand: "",
        comments: "",
      },
      
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    equipForm: {
      name: {required, maxLength:maxLength(64)},
      category: {required},
      type: {required},
      brand: {required},
    },
    
  },
  mounted() {
    this.fetchEquipmentsTypes();
    this.fetchEquipmentsCategs();
    this.fetchEquipmentsBrands();
    this.fetchEquipmentDetails();
  },
  methods: {
    fetchEquipmentDetails(){
      fetchSingleEquipmentDetailsApi(this.$route.params.uid)
      .then((res) => {
        var eq = res.data.original.equipment;
        this.equipForm.uuid = eq.uuid;
        this.equipForm.name = eq.name;
        this.equipForm.description = eq.description;
        this.equipForm.category = eq.category;
        this.equipForm.type = eq.type;
        this.equipForm.brand = eq.brand;
        this.equipForm.comments = eq.comments;
      })
        .catch(() => {})
        .finally(() => {});
    },

    fetchEquipmentsTypes(){
      fetchEquipmentsTypesApi()
        .then((res) => (this.types = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },

    fetchEquipmentsCategs(){
      fetchEquipmentsCategsApi()
        .then((res) => (this.categories = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },

    fetchEquipmentsBrands(){
      fetchEquipmentsBrandsApi()
        .then((res) => (this.brands = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http.post('/infrastructure/equipments/update', this.equipForm)

      .then((res) => {
          var status = res.data.original.status;
          switch(status){
            case 200: 
              this.$toast.success(res.data.original.msg);
              router.push({ name: 'base.misc.equipments' });
              
            break;

            case 500: 
              this.$toast.warning(res.data.original.msg);
            break;
          }
      })
      .catch((error) => {
          this.$toast.error(error.message);
      }).finally(() => {
      });
    }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Désignation *</label>
                    <input
                      id="deptName"
                      v-model="equipForm.name"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.equipForm.name.$error }"
                    />
                    <div v-if="submitted && $v.equipForm.name.$error" class="invalid-feedback">
                      <span v-if="!$v.equipForm.name.required">Le nom est obligatoire.</span>
                      <span v-if="!$v.equipForm.name.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Catégorie *</label>
                    <multiselect
                      v-model="equipForm.category"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="categories"
                      placeholder="Select category"
                      :allow-empty="false"
                      :class="{ 'is-invalid': submitted && $v.equipForm.category.$error }"
                    >
                      <template slot="singleCateg" slot-scope="{ categ }">{{
                        categ.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.equipForm.category.$error" class="invalid-feedback">
                      <span v-if="!$v.equipForm.category.required">Catégorie obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Type *</label>
                    <multiselect
                      v-model="equipForm.type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="types"
                      placeholder="Select type"
                      :allow-empty="false"
                      :class="{ 'is-invalid': submitted && $v.equipForm.type.$error }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.equipForm.type.$error" class="invalid-feedback">
                      <span v-if="!$v.equipForm.type.required">Type obligatoire.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Marque *</label>
                    <multiselect
                      v-model="equipForm.brand"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="brands"
                      placeholder="Select brand"
                      :allow-empty="false"
                      :class="{ 'is-invalid': submitted && $v.equipForm.brand.$error }"
                    >
                      <template slot="singleBrand" slot-scope="{ brand }">{{
                        brand.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.equipForm.brand.$error" class="invalid-feedback">
                      <span v-if="!$v.equipForm.brand.required">Marque obligatoire.</span>
                    </div>
                  </div>
                </div>                
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02">Commentaires</label>
                    <textarea class="form-control" id="" cols="30" rows="10"></textarea>
                    
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>