var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "deptName" } }, [
                          _vm._v("Désignation *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.equipForm.name,
                              expression: "equipForm.name"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.equipForm.name.$error
                          },
                          attrs: {
                            id: "deptName",
                            type: "text",
                            placeholder: "",
                            value: ""
                          },
                          domProps: { value: _vm.equipForm.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.equipForm,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.equipForm.name.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.equipForm.name.required
                                ? _c("span", [
                                    _vm._v("Le nom est obligatoire.")
                                  ])
                                : _vm._e(),
                              !_vm.$v.equipForm.name.maxLength
                                ? _c("span", [
                                    _vm._v(
                                      "La taille du champs est limitée a 64 charactères."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "deptName" } }, [
                            _vm._v("Catégorie *")
                          ]),
                          _c("multiselect", {
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.equipForm.category.$error
                            },
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.categories,
                              placeholder: "Select category",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleCateg",
                                fn: function(ref) {
                                  var categ = ref.categ
                                  return [_vm._v(_vm._s(categ.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.equipForm.category,
                              callback: function($$v) {
                                _vm.$set(_vm.equipForm, "category", $$v)
                              },
                              expression: "equipForm.category"
                            }
                          }),
                          _vm.submitted && _vm.$v.equipForm.category.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.equipForm.category.required
                                  ? _c("span", [
                                      _vm._v("Catégorie obligatoire.")
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "deptName" } }, [
                            _vm._v("Type *")
                          ]),
                          _c("multiselect", {
                            class: {
                              "is-invalid":
                                _vm.submitted && _vm.$v.equipForm.type.$error
                            },
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.types,
                              placeholder: "Select type",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleType",
                                fn: function(ref) {
                                  var type = ref.type
                                  return [_vm._v(_vm._s(type.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.equipForm.type,
                              callback: function($$v) {
                                _vm.$set(_vm.equipForm, "type", $$v)
                              },
                              expression: "equipForm.type"
                            }
                          }),
                          _vm.submitted && _vm.$v.equipForm.type.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.equipForm.type.required
                                  ? _c("span", [_vm._v("Type obligatoire.")])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "deptName" } }, [
                            _vm._v("Marque *")
                          ]),
                          _c("multiselect", {
                            class: {
                              "is-invalid":
                                _vm.submitted && _vm.$v.equipForm.brand.$error
                            },
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.brands,
                              placeholder: "Select brand",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleBrand",
                                fn: function(ref) {
                                  var brand = ref.brand
                                  return [_vm._v(_vm._s(brand.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.equipForm.brand,
                              callback: function($$v) {
                                _vm.$set(_vm.equipForm, "brand", $$v)
                              },
                              expression: "equipForm.brand"
                            }
                          }),
                          _vm.submitted && _vm.$v.equipForm.brand.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.equipForm.brand.required
                                  ? _c("span", [_vm._v("Marque obligatoire.")])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "validationCustom02" } }, [
                          _vm._v("Commentaires")
                        ]),
                        _c("textarea", {
                          staticClass: "form-control",
                          attrs: { id: "", cols: "30", rows: "10" }
                        })
                      ])
                    ])
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("Enregistrer")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }